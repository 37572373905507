import cx from "classnames"

interface ChoiceToggleTypes {
  selection: boolean
  setSelection: (selected: boolean) => void
  leftText: string
  rightText: string
  className?: string
  leftBtnClassName?: string
  rightBtnClassName?: string
}

const ChoiceToggle = ({
  selection,
  setSelection,
  leftText,
  rightText,
  className,
  leftBtnClassName,
  rightBtnClassName,
}: ChoiceToggleTypes) => {
  return (
    <div className={cx("flex justify-center max-w-[14rem] m-auto", className)}>
      <div className="relative flex p-1 bg-grass rounded-full">
        <span
          className="absolute inset-0 m-1 pointer-events-none"
          aria-hidden="true"
        >
          <span
            className={cx(
              "absolute inset-0 w-1/2 bg-white rounded-full transform transition-transform duration-150 ease-in-out px-4",
              selection ? "translate-x-0" : "translate-x-full"
            )}
          />
        </span>

        <button
          className={cx(
            "relative flex-1 w-1/2 text-xs h-[26px] rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 ease-in-out px-4",
            selection ? "text-charcoal-500" : "text-white",
            leftBtnClassName
          )}
          onClick={() => setSelection(true)}
          aria-pressed={selection}
        >
          {leftText}
        </button>

        <button
          className={cx(
            "relative flex-1 w-1/2 text-xs h-[26px] rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 transition-colors duration-150 ease-in-out px-4",
            selection ? "text-white" : "text-charcoal-500",
            rightBtnClassName
          )}
          onClick={() => setSelection(false)}
          aria-pressed={selection}
        >
          {rightText}
        </button>
      </div>
    </div>
  )
}

export default ChoiceToggle
