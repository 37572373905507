import { useState } from "react"
import { AxiosError } from "axios"
import { Link } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"
import {
  useTooltipState,
  Tooltip as ReakitTooltip,
  TooltipReference,
  TooltipArrow,
} from "reakit/Tooltip"

import ChoiceToggle from "./ChoiceToggle"
import CheckmarkListItem from "./CheckmarkListItem"
import { Toast } from "./Toast"
import { useAccount, useAccountId, useCreateStripeSession } from "../hooks"
import { genericErrMsg } from "../api/auth"

interface BecomeAMemberWidgetTypes {
  widgetType: "page" | "dashboard" | "sidebar"
  dashboardLink?: boolean
}

const BecomeAMemberWidget = ({
  dashboardLink = false,
  widgetType = "page",
}: BecomeAMemberWidgetTypes) => {
  const tooltip = useTooltipState()
  const queryClient = useQueryClient()
  const accountId = useAccountId()

  const [isAnnual, setIsAnnual] = useState<boolean>(true)
  const { data: account, isLoading: accountIsLoading } = useAccount(
    queryClient,
    accountId
  )

  const { mutateAsync: createStripeSession } = useCreateStripeSession(
    queryClient,
    accountId,
    {
      onSuccess: (data: { url: string }) => {
        window.location.href = data.url
      },
      onError: (error) => {
        const axiosError = error as AxiosError
        Toast.error(axiosError?.message || genericErrMsg)
      },
    }
  )

  const handleStripeSubmit = async () => {
    const postData = {
      lookup_key: isAnnual ? "basic_annual" : "basic_monthly",
    }

    await createStripeSession(postData)
  }

  const isPage = widgetType === "page"
  const isSidebar = widgetType === "sidebar"
  const isDashboard = widgetType === "dashboard"

  if (accountIsLoading || account?.is_member) {
    return null
  }

  return (
    <div
      className={cx(
        "w-full",
        {
          "max-w-[816px] mx-auto": isPage,
        },
        {
          "lg:w-[302px]": isSidebar,
        }
      )}
    >
      {!isSidebar && !isDashboard ? (
        <h2
          className={cx(
            "text-xl md:text-3xl text-autumn font-medium leading-[140%] md:leading-[124%] tracking-0.4 md:tracking-[0.6px] mt-7",
            { "text-center": isPage }
          )}
        >
          Become a member today
        </h2>
      ) : null}

      <div
        className={cx(
          "w-full border border-dusk-50 rounded py-4 pr-8 pl-7",
          { "mt-7": isPage },
          { "bg-white mt-0 mb-0": isDashboard },
          { "mb-3": dashboardLink }
        )}
      >
        {isSidebar || isDashboard ? (
          <h2
            className={cx(
              "text-xl text-autumn font-medium leading-[140%] tracking-0.4",
              {
                "lg:text-3xl lg:leading-[124%] lg:tracking-[0.6px]":
                  isDashboard,
              }
            )}
          >
            Become a member today
          </h2>
        ) : null}

        <div
          className={cx(
            { "md:flex md:gap-x-5": isPage },
            { "xl:flex xl:gap-x-5 mt-8 md:mt-6": isDashboard },
            { "md:flex lg:block md:gap-x-5 mt-8": isSidebar }
          )}
        >
          <div className="grow">
            <div className="flex gap-2 items-center">
              <h4 className="text-sm font-medium font-overline uppercase leading-[150%] tracking-[1.68px] flex-0">
                Member Benefits
              </h4>

              <div className="h-0 border-b border-[#C0C8CE] grow" />
            </div>

            <ul
              className={cx(
                "grid grid-cols-1 gap-x-11 mt-6",
                { "sm:grid-cols-2": isPage },
                {
                  "lg:grid-cols-2": isDashboard,
                },
                { "sm:grid-cols-2 lg:grid-cols-1": isSidebar }
              )}
            >
              <CheckmarkListItem>View all programs</CheckmarkListItem>
              <CheckmarkListItem className="mt-1">
                Filter by your eligibility
              </CheckmarkListItem>
              <CheckmarkListItem className="mt-1">
                Eligibility Maps
              </CheckmarkListItem>
              <CheckmarkListItem className="mt-1">
                Program contract details
              </CheckmarkListItem>
              <CheckmarkListItem className="mt-1">
                Exclusive content and analysis
              </CheckmarkListItem>
              <CheckmarkListItem className="mt-1">
                Stackable programs
              </CheckmarkListItem>
            </ul>

            <p
              className={cx(
                "hidden text-xs text-charcoal-500 leding-[140%] tracking-0.36 mt-8",
                { block: isSidebar },
                { "md:block": isPage },
                { "xl:block": isDashboard },
                { "md:block lg:hidden": isSidebar }
              )}
            >
              <strong>Money Back Guarantee!</strong> If you're unhappy with your
              membership, we'll issue a full refund.
            </p>
          </div>

          <div
            className={cx(
              "flex flex-col w-full shrink-0 bg-sand border border-charcoal-50 rounded pt-5 px-9 pb-4 mt-5",
              { "md:w-[230px] md:mt-0": isPage },
              { "xl:w-[230px] xl:mt-0": isDashboard },
              { "md:w-[230px] lg:w-full md:mt-0 lg:mt-5": isSidebar }
            )}
          >
            <ChoiceToggle
              selection={isAnnual}
              setSelection={setIsAnnual}
              leftText="Yearly"
              rightText="Monthly"
              className="mt-0 mb-auto"
              leftBtnClassName="member-widget-yearly-membership"
              rightBtnClassName="member-widget-monthly-membership"
            />

            {isAnnual ? (
              <p className="w-[145px] bg-grass-100 rounded text-charcoal-900 text-xs text-center font-bold leading-[21px] tracking-0.36 m-auto mt-1">
                Save 25%
              </p>
            ) : null}

            <p className="text-2xl text-charcoal-500 text-center font-medium leading-[124%] tracking-0.48 pt-3 mt-auto">
              {isAnnual ? "$9/month" : "$12/month"}
            </p>

            <p className="text-xs text-charcoal-500 text-center leding-[140%] tracking-0.36">
              {isAnnual ? "Billed $108/yearly" : "Billed monthly"}
            </p>

            <TooltipReference {...tooltip} className="ring-0">
              <button
                className="member-widget-join-now max-w-40 btn2 btn2-primary font-semibold btn2-block mx-auto mt-3 mb-0"
                onClick={() => {
                  handleStripeSubmit()
                }}
                disabled={account?.role !== "admin"}
              >
                Join Now
              </button>
            </TooltipReference>

            {account?.role !== "admin" ? (
              <ReakitTooltip {...tooltip} className="st-tooltip">
                <div className="text-sm max-w-sm p-3 st-tooltip-inner">
                  <TooltipArrow {...tooltip} />
                  Only admins can subscribe.
                </div>
              </ReakitTooltip>
            ) : null}
          </div>
        </div>

        <p
          className={cx(
            "text-xs text-charcoal-500 leding-[140%] tracking-0.36 mt-3",
            { "xl:hidden": isDashboard },
            { "md:hidden": isPage },
            { "md:hidden lg:block": isSidebar }
          )}
        >
          <strong>Money Back Guarantee!</strong> If you're unhappy with your
          membership, we'll issue a full refund.
        </p>
      </div>

      {dashboardLink ? (
        <Link
          to="/"
          className="member-widget-dashboard-link text-lg text-leaf leading-[140%] tracking-0.36"
        >
          No thanks, bring me to my Dashboard
        </Link>
      ) : null}
    </div>
  )
}

export default BecomeAMemberWidget
