import { Link } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"

import SettingsLayout from "../_layouts/Settings"
import { Toast } from "../../components/Toast"
import PropertyDetailsForm from "../../sections/PropertyDetails/PropertyDetailsForm"
import {
  useAccount,
  useAccountId,
  useAccountAbsoluteUrl,
  useAccountProperty,
} from "../../hooks"
import { AccountTypes } from "../../types/account"
import { AccountProperty } from "../../types/property"

const PropertyDetails = () => {
  const accountId = useAccountId()
  const accountUrl = useAccountAbsoluteUrl()
  const queryClient = useQueryClient()

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId)

  return (
    <SettingsLayout
      isLoading={[accountIsLoading, propertyIsLoading]}
      title="Property Details"
      subtitle="Property details will be used as part of our assessment process."
    >
      {account && property && (
        <PropertyDetailsForm
          backLink={<Link to={accountUrl}>Cancel</Link>}
          initialValues={account}
          property={property}
          onSuccess={() => {
            Toast.success("Property details updated")
          }}
          submitText="Save Changes"
        />
      )}
    </SettingsLayout>
  )
}

export default PropertyDetails
