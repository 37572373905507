import { Outlet } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import { NavBar } from "../../components/NavBar"
import { useProfile } from "../../hooks"
import { useAccessToken } from "../../stores"
import { Profile } from "../../types"

interface NavLayoutTypes {
  partnersNav?: boolean
  bgClass?: string
}

const NavLayout = ({ partnersNav = false, bgClass = "" }: NavLayoutTypes) => {
  const accessToken = useAccessToken()
  const queryClient = useQueryClient()

  const { data: profile } = useProfile<Profile, Error>(queryClient, {
    enabled: !!accessToken,
  })

  return (
    <div className={cx("flex flex-col min-h-full", bgClass)}>
      {!!accessToken && (
        <NavBar
          isEmailVerified={!!profile?.is_email_verified}
          partnersNav={partnersNav}
        />
      )}
      <Outlet />
    </div>
  )
}

export default NavLayout
