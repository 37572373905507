import { Profile } from "@/types"

interface ConfigTypes {
  enabled?: boolean
  profile?: Profile
}

export const useAppcues = (config: ConfigTypes = {}) => {
  if (!config.enabled) return

  const { profile } = config

  window.Appcues?.identify(
    `${profile?.email}`, // unique, required
    {
      // optional properties - here as a guide for what you can add

      accountId: `${profile?.id}`, // Current user's account ID
      firstName: `${profile?.first_name}`, // current user's first name
      lastName: `${profile?.last_name}`,
      email: `${profile?.email}`, // Current user's email
      //   createdAt: 1566932390, // Unix timestamp of user signup date
      //   purchasedAd: 1566932395, // Unix timestamp of account purchase date (leave null if empty)
      //   planTier: "Standard", // Current user’s plan tier
      //   role: "Admin", // Current user’s role or permissions
      // companyName: "Acme Corp", // Current user’s company name
      //   location: "90210", // a zipcode, state, or country enables location-based targeting
      //   version: "2.0", // users on different versions may need to see different content
      //   language: "spanish", // for multi-language applications
      //   renewalDate: 1577880288, // to remind users to renew
    }
  )
}
