import { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import Fireworks from "react-canvas-confetti/dist/presets/fireworks"
import _ from "lodash"

import DashboardLayout from "../_layouts/Dashboard"
import NotificationContent from "../../sections/ViewAccount/NotificationContent"
import {
  useAccountId,
  useAccount,
  useAccountProperty,
  useAccountNotifications,
  useProfile,
  useDashboardAccounts,
  useIsMultiAccount,
  useProjectList,
  useGetAttestationQuizes,
  useAccountInterest,
} from "../../hooks"
import { Toast } from "../../components/Toast"
import { SubNavigation } from "../../components/SubNavigation"
import { useSessionStore } from "../../stores"
import { getProjectsData } from "../../shared/utils"
import BookAnExpertConsultation from "../../sections/ViewAccount/BookAnExpertConsultation"
import ProjectInterestForm from "../../sections/ProjectInterest/ProjectInterestForm"
import Land from "../../sections/ViewAccount/Land"
import { ContentCard } from "../../components/Card"
import InviteYourNeighbor from "../../sections/ViewAccount/InviteYourNeighbor"
import AccountStatus from "../../sections/ViewAccount/AccountStatus"
import PartnerInvites from "../../sections/ViewAccount/PartnerInvites"
import DashboardAccountInfo from "../../sections/ViewAccount/DashboardAccountInfo"
import NaturalCapitalEstimator from "../../sections/ViewAccount/NaturalCapitalEstimator/NaturalCapitalEstimator"
import NaturalCapitalPortfolio from "../../sections/ViewAccount/NaturalCapitalPortfolio"
import ProgramsCarousel from "../../sections/ViewAccount/ProgramsCarousel"
import CompleteSurveyNotification from "../../sections/ViewAccount/CompleteSurveyNotification"
import { FEATURE_FLAGS } from "../../shared/constants"
import useFeatureFlags from "../../hooks/useFeatureFlags"
import { AccountTypes } from "../../types/account"
import { Profile } from "../../types"
import { ProjectDataTypes, ProjectListTypes } from "../../types/program"
import { NotificationTypes } from "../../types/notifications"
import { AccountProperty } from "../../types/property"
import { EligibilityQuizzes } from "@/types/eligibility"
import { AccountInterestTypes } from "@/types/accountInterest"

const ViewAccount = ({ showGlobalNotifications = false }) => {
  // Load in our data/hooks
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const isMultiAccount = useIsMultiAccount()
  const accountId = useAccountId()

  const [isCheckoutSuccess, setIsCheckoutSuccess] = useState<boolean>(false)
  const [showSurveyNotification, setShowSurveyNotification] =
    useState<boolean>(false)

  // Load our data
  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)

  const { data: projectList, isLoading: projectListIsLoading } = useProjectList<
    ProjectListTypes,
    Error
  >(queryClient, accountId, {})

  const programsData: ProjectDataTypes[] = useMemo(() => {
    return getProjectsData(projectList)
  }, [projectList])

  // DEV: randomize sponsored programs order
  const sponsoredPrograms = useMemo(() => {
    return _.shuffle(programsData.filter((program) => program.is_sponsored))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programsData.length])

  const { data: notifications, isLoading: notificationsIsLoading } =
    useAccountNotifications<NotificationTypes[], Error>(queryClient, accountId)

  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId)

  const { data: accountInterestData, isLoading: accountInterestIsLoading } =
    useAccountInterest<AccountInterestTypes, Error>(queryClient, accountId)

  const { data: quizzesData, isLoading: quizDataIsLoading } =
    useGetAttestationQuizes<EligibilityQuizzes, Error>(accountId)

  // DEV: We should only load this if a cycle is "created" but that's a dependent query (bad perf), so always fetch
  const { data: accounts, isLoading: accountsIsLoading } =
    useDashboardAccounts(queryClient)

  const accountsCount: number | undefined = accounts && accounts.count
  const { setAccountValueOverride } = useSessionStore((state) => state)

  const dataLoaded = !!(
    account &&
    accounts &&
    property &&
    notifications &&
    profile &&
    projectList &&
    quizzesData &&
    accountInterestData
  )

  // DEV: Place `useEffect` before any `return` calls to avoid issues with rule of hooks
  useEffect(() => {
    if (!dataLoaded) {
      return
    }
  }, [
    dataLoaded,
    accountId,
    setAccountValueOverride,
    profile,
    account,
    projectList,
  ])

  // DEV: Check if the URL contains the stripe "checkout-success" query parameter, and remove it.
  useEffect(() => {
    const url = new URL(window.location.href)
    const searchParams = url.searchParams

    if (searchParams.has("checkout-success")) {
      searchParams.delete("checkout-success")

      const newUrl = `${url.pathname}${
        searchParams.toString() ? `?${searchParams.toString()}` : ""
      }`

      window.history.replaceState({}, document.title, newUrl)

      setIsCheckoutSuccess(true)

      Toast.success("Congrats! Your NCX purchase was successful.")
    }
  }, [navigate])

  useEffect(() => {
    if (!dataLoaded) {
      return
    }
    if (!accountInterestData.has_answered_all_questions) {
      setShowSurveyNotification(true)
    }
  }, [dataLoaded, accountInterestData])

  const { flagEnabled: nciRolloutEnabled, clientReady } = useFeatureFlags(
    FEATURE_FLAGS.nci_rollout
  )

  return (
    <DashboardLayout
      showGlobalNotifications={showGlobalNotifications}
      isLoading={[
        accountIsLoading,
        accountsIsLoading,
        propertyIsLoading,
        notificationsIsLoading,
        profileIsLoading,
        projectListIsLoading,
        quizDataIsLoading,
        accountInterestIsLoading,
      ]}
      subNavigation={
        dataLoaded && isMultiAccount && accountId !== "_single" ? (
          <SubNavigation
            aria-label="Back to dashboard"
            title={account.account_name}
            backUrl="/"
            isAccountsPage={true}
          />
        ) : null
      }
    >
      {dataLoaded && (
        <div className="grid grid-cols-1 gap-6  md:grid-cols-3 md:gap-6">
          {/* DEV: If col-span-2 runs with grid-cols-1, then Firefox does strange horizontal sizing */}
          <div className="md:col-span-2 space-y-8">
            {/* DEV: Content is nested inside of column so the entire column doesn't get the full height card */}
            <NotificationContent
              accountId={accountId}
              accountsCount={accountsCount}
              notifications={notifications}
            />
            {showSurveyNotification &&
              !accountInterestData.has_answered_all_questions && (
                <CompleteSurveyNotification
                  onDismiss={() => setShowSurveyNotification(false)}
                />
              )}

            {isCheckoutSuccess === false &&
            account?.has_purchased_nca_report === false ? (
              <BookAnExpertConsultation accountRole={account.role} />
            ) : null}

            {clientReady &&
            nciRolloutEnabled &&
            property?.index_data &&
            (property?.landcover_data?.forest?.acres ?? 0) >= 5 ? (
              <NaturalCapitalEstimator
                indexData={property?.index_data}
                hasPurchasedNCAReport={account?.has_purchased_nca_report}
              />
            ) : null}

            <NaturalCapitalPortfolio quizzesData={quizzesData} />

            {!account?.has_project_interest_info && (
              <ContentCard>
                <h3 className="text-xl mb-6">
                  Tell us what type of programs you're interested in
                </h3>
                <div className="text-overline">Program Interest</div>
                <p className="mt-2 mb-8 md:mb-6 text-base leading-130">
                  We'll use this information to share opportunities that you're
                  interested in.
                </p>
                <ProjectInterestForm
                  accountData={account}
                  submitText="Save Program Interest"
                  onSuccess={() =>
                    Toast.success(`Program interest added to account`)
                  }
                />
              </ContentCard>
            )}
            {property?.landcover_data && (
              <ContentCard>
                <Land
                  accountId={accountId}
                  profile={profile}
                  property={property}
                  isMember={account.is_member}
                />
              </ContentCard>
            )}
          </div>
          <div>
            <AccountStatus
              isMember={account?.is_member}
              memberSince={account?.member_since_date}
            />

            <DashboardAccountInfo
              acreage={property.acreage}
              accountInterestData={accountInterestData}
            />

            <ProgramsCarousel programsData={sponsoredPrograms} />

            <PartnerInvites />

            <InviteYourNeighbor />
          </div>
        </div>
      )}

      {isCheckoutSuccess ? (
        <Fireworks autorun={{ speed: 3, duration: 3000 }} />
      ) : null}
    </DashboardLayout>
  )
}

export default ViewAccount
