import { PROJECT_ACTIONS } from "../shared/constants"
import { StateTypes, ActionType } from "../context/ProjectsContext"
import { ProgramCategoryType } from "../types/constants"
import { PaymentTypeKeysType } from "../types/program"

export const projectsReducer = (
  state: StateTypes,
  { type, payload }: ActionType
) => {
  const actions = {
    [PROJECT_ACTIONS.setCategory]: {
      ...state,
      category: payload as ProgramCategoryType,
    },
    [PROJECT_ACTIONS.setGovtProjectsOn]: {
      ...state,
      govtProjectsOn: payload as boolean,
    },
    [PROJECT_ACTIONS.setIneligibleProjectsOn]: {
      ...state,
      ineligibleProjectsOn: payload as boolean,
    },
    [PROJECT_ACTIONS.setHasLandownerCost]: {
      ...state,
      hasLandownerCost: payload as boolean,
    },
    [PROJECT_ACTIONS.setTermLengthFilter]: {
      ...state,
      termLengthFilter: payload as [number, number],
    },
    [PROJECT_ACTIONS.setPaymentTypes]: {
      ...state,
      paymentTypes: payload as Record<PaymentTypeKeysType, boolean>,
    },
    [PROJECT_ACTIONS.setSearchProjects]: {
      ...state,
      searchProjects: payload as string,
    },
    [PROJECT_ACTIONS.setInitialState]: {
      ...(payload as StateTypes),
    },
    default: state,
  }
  return actions[type] || actions.default
}
