import { Link, useNavigate, useLocation } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { useDialogState } from "reakit/Dialog"

import SettingsLayout from "../_layouts/Settings"
import Property from "../../sections/Property"
import { Toast } from "../../components/Toast"
import {
  useAccountId,
  useAccountUrlPrefix,
  useAccountProperty,
} from "../../hooks"
import { AccountProperty } from "../../types/property"

const PropertyBoundaries = () => {
  const accountId = useAccountId()
  const accountUrlPrefix = useAccountUrlPrefix()
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const uploadDialog = useDialogState({ animated: true })
  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId)

  const from: string =
    location.state?.from?.pathname ||
    `${accountUrlPrefix}/settings/property-details`

  // DEV: We use `SettingsLayout` so top-level components (e.g. formerly Drift) always load
  return (
    <SettingsLayout title="Property Boundaries" isLoading={propertyIsLoading}>
      {property && (
        <Property
          property={property}
          backLink={
            <Link to={from}>
              {property.is_bounds_editing_disabled ? "Back" : "Cancel"}
            </Link>
          }
          submitText="Save Changes"
          onSuccess={() => {
            Toast.success(
              "Your property boundaries have been successfully updated."
            )
            navigate(from)
          }}
          uploadDialog={uploadDialog}
        />
      )}
    </SettingsLayout>
  )
}

export default PropertyBoundaries
