import { ComponentType, useMemo } from "react"
import { useQueryClient } from "@tanstack/react-query"

import SettingsLayout from "../_layouts/Settings"
import { Notification } from "../../components/Notification"
import UploadedDocumentsSection from "../../sections/Documents/UploadedDocumentsSection"
import CreatedDocumentsSection from "../../sections/Documents/CreatedDocumentsSection"
import {
  useAccountId,
  useAccountAccountCycles,
  useAccountOwnershipVerification,
} from "../../hooks"
import { AccountCycleTypes } from "../../types/accountCycles"
import { OwnershipVerificationTypes } from "../../types/ownershipVerification"
import {
  ACCOUNT_CYCLE_DOCUMENT_CONFIG,
  ACCOUNT_DOCUMENT_CONFIG,
} from "../../sections/Documents/documentsHelpers"

interface AccountCyclesToRenderType {
  accountCycle: AccountCycleTypes
  createdDocumentComponents: ComponentType<any>[]
}

const Documents = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const {
    data: ownershipDocumentsData,
    isLoading: ownershipDocumentsIsLoading,
  } = useAccountOwnershipVerification<OwnershipVerificationTypes, Error>(
    queryClient,
    accountId
  )
  const { data: accountCyclesData, isLoading: accountCyclesDataIsLoading } =
    useAccountAccountCycles<AccountCycleTypes[], Error>(queryClient, accountId)
  // One day this may move to `object_list`, but not today, https://app.asana.com/0/1201653128864814/1201759560003317/f
  const accountCycles = accountCyclesData?.filter(
    (accountCycle: AccountCycleTypes) => accountCycle?.cycle_key !== "23D"
  )
  const ownershipDocuments = ownershipDocumentsData

  const dataLoaded = !!accountCyclesData && !!ownershipDocumentsData

  const uploadedDocumentComponents: ComponentType<any>[] = useMemo(() => {
    if (!dataLoaded) return []

    return ACCOUNT_DOCUMENT_CONFIG.map(({ component }) => component)
  }, [dataLoaded])

  const accountCyclesToRender: AccountCyclesToRenderType[] = useMemo(() => {
    if (!dataLoaded) return []

    const accountCycleComponents = accountCycles?.map((accountCycle) => {
      const createdDocumentComponents = ACCOUNT_CYCLE_DOCUMENT_CONFIG.filter(
        ({ condition }) => condition(accountCycle)
      ).map(({ component }) => component)

      return {
        accountCycle,
        createdDocumentComponents,
      }
    })

    const accountCyclesWithComponents = accountCycleComponents?.filter(
      ({ createdDocumentComponents }) => createdDocumentComponents.length
    )
    const accountCyclesWithoutComponents = accountCycleComponents?.filter(
      ({ createdDocumentComponents }) => !createdDocumentComponents.length
    )

    // Normally, we only want to render cycles with DocumentLinks
    const cyclesToRender = accountCyclesWithComponents?.slice() || []
    // However, they may have re-enrollment open (or no DocumentLink cycles at all)
    //   and so we push the only non-empty one (most recent one) onto our array
    // DEV: Order is guaranteed by our server
    if (
      accountCyclesWithoutComponents?.length &&
      accountCycleComponents &&
      accountCyclesWithoutComponents[0] === accountCycleComponents[0]
    ) {
      cyclesToRender.unshift(accountCyclesWithoutComponents[0])
    }

    return cyclesToRender
  }, [accountCycles, dataLoaded])

  const hasAccountCyclesDocuments = useMemo(
    () =>
      accountCyclesToRender.some(
        (cycle) => cycle.createdDocumentComponents.length > 0
      ),
    [accountCyclesToRender]
  )

  const hasOwnershipDocuments = useMemo(
    () => ownershipDocuments && ownershipDocuments.zipfile_url.length > 0,
    [ownershipDocuments]
  )

  return (
    <SettingsLayout
      isLoading={[accountCyclesDataIsLoading, ownershipDocumentsIsLoading]}
      title="Documents"
    >
      {dataLoaded && (
        <>
          <Notification
            title="Feature removal warning!"
            variant="error"
            border="full"
            className="mb-8"
          >
            <p>
              The Documents feature is no longer supported and will be removed
              after November 30, 2024.
            </p>

            {hasAccountCyclesDocuments || hasOwnershipDocuments ? (
              <p className="mt-2">
                If you would like to keep the documents linked below, please
                click the links to download them before November 30, 2024.
              </p>
            ) : null}
          </Notification>

          <UploadedDocumentsSection
            documentComponents={uploadedDocumentComponents}
            ownershipDocuments={ownershipDocuments}
          />
          <hr className="border-cloud mt-6 mb-6" />
          {accountCyclesToRender.map(
            ({ accountCycle, createdDocumentComponents }) => (
              <CreatedDocumentsSection
                accountId={accountId}
                accountCycle={accountCycle}
                documentComponents={createdDocumentComponents}
                key={accountCycle.cycle_key}
              />
            )
          )}
        </>
      )}
    </SettingsLayout>
  )
}

export default Documents
