import { CSSProperties } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/pro-duotone-svg-icons"

import MainLayout from "./_layouts/Main"
import AskAQuestionForm from "../components/AskAQuestionForm"
import {
  useAccountId,
  useAccount,
  useAccountUrlPrefix,
  useProfile,
} from "../hooks"
import { Profile } from "@/types"
import { AccountTypes } from "@/types/account"
import ONBOARDING from "../images/onboarding-survey.webp"

const AskAQuestion = () => {
  const accountId = useAccountId()
  const accountUrlPrefix = useAccountUrlPrefix()
  const queryClient = useQueryClient()
  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  return (
    <MainLayout
      isLoading={[profileIsLoading, accountIsLoading]}
      contentBg="white"
      className="ask-a-question-page"
    >
      <div className="pb-24 sm:pb-0">
        <div className="flex justify-end px-4 sm:px-[72px] py-4 sm:pt-12 sm:pb-[76px]">
          <Link
            to={`${accountUrlPrefix}/`}
            className="ask-a-question-close text-charcoal-900"
          >
            <FontAwesomeIcon
              icon={faXmark}
              style={
                {
                  "--fa-primary-opacity": 0,
                  "--fa-secondary-opacity": 1,
                } as CSSProperties
              }
            />
          </Link>
        </div>
        <div className="container xl:max-w-[1440px] flex justify-between gap-8">
          <div className="hidden md:block w-auto max-w-[528px]">
            <img
              src={ONBOARDING}
              alt="Ebony Tree Image"
              className="w-auto max-h-[480px]"
            />
          </div>

          <div className="md:w-[500px] max-w-full">
            <div className="mb-6">
              <h1 className="text-charcoal-500 text-2xl tracking-wide font-medium">
                Get advice from NCX experts
              </h1>
              <p className="text-gray-500 text-base font-normal leading-snug tracking-tight">
                Share your top question or concern. We'll help guide you towards
                the right path based on your land, goals, and market conditions.
              </p>
            </div>
            <AskAQuestionForm account={account} profile={profile} />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default AskAQuestion
