import React, { ReactNode, useReducer } from "react"
import { initialState, ProjectsContext } from "./ProjectsContext"
import { projectsReducer } from "../reducers/projectsReducer"

interface ProjectsProviderTypes {
  children: ReactNode
}

const ProjectsProvider = ({ children }: ProjectsProviderTypes) => {
  const [state, dispatch] = useReducer(projectsReducer, initialState)

  return (
    <ProjectsContext.Provider value={{ state, dispatch }}>
      {children}
    </ProjectsContext.Provider>
  )
}

export default ProjectsProvider
