import { useQueryClient } from "@tanstack/react-query"

import SettingsLayout from "../_layouts/Settings"
import ProjectInterestForm from "../../sections/ProjectInterest/ProjectInterestForm"
import { useAccount, useAccountId } from "../../hooks"
import { Toast } from "../../components/Toast"
import { AccountTypes } from "../../types/account"

const ProjectInterest = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  return (
    <SettingsLayout
      isLoading={accountIsLoading}
      title="Program Interest"
      subtitle="We'll use this information to share opportunities that you're interested in."
    >
      {account && (
        <ProjectInterestForm
          accountData={account}
          submitText="Save Changes"
          onSuccess={() => {
            Toast.success("Program Interest information updated")
          }}
        />
      )}
    </SettingsLayout>
  )
}

export default ProjectInterest
