import { heapTrackEvent } from "../api/integrations"
import AspectRatio from "react-aspect-ratio"
import "react-aspect-ratio/aspect-ratio.css"

interface VideoTypes {
  name: string
  className?: string
  src: string
  thumbnailSrc?: string
  ratio?: string
  minWidth?: number | undefined
  maxHeight?: number
}

export const Video = ({
  name,
  className,
  src,
  thumbnailSrc,
  ratio = "16/9",
  minWidth,
  maxHeight = 600,
}: VideoTypes) => {
  // Track video interaction for Heap
  // https://developers.heap.io/docs/tracking-video-plays-in-heap#tracking-video-elements
  const onPlaying = () => {
    heapTrackEvent("video-play", { name, src })
  }
  const onPause = () => {
    heapTrackEvent("video-pause", { name, src })
  }
  const onEnded = () => {
    heapTrackEvent("video-ended", { name, src })
  }

  return (
    <AspectRatio
      ratio={ratio}
      style={{ minWidth: minWidth, maxHeight: maxHeight }}
    >
      <video
        controls
        className={className}
        poster={thumbnailSrc}
        onPlaying={onPlaying}
        onPause={onPause}
        onEnded={onEnded}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    </AspectRatio>
  )
}
