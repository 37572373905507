import { ReactNode } from "react"
import cx from "classnames"
import { Link } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"

import {
  buildAccountAbsoluteUrl,
  useDeleteGlobalNotification,
  useGlobalNotifications,
} from "../../hooks"
import MainLayout from "./Main"
import { Notification } from "../../components/Notification"

export interface NotificationType {
  id: string
  account_id: string
  account_name: string
  key: string
}

interface GlobalNotificationTypes {
  notification: NotificationType
}

interface DashboardLayoutTypes {
  showGlobalNotifications?: boolean
  children: ReactNode
  subNavigation?: ReactNode
  isLoading?: boolean[] | boolean
  isAccountsPage?: boolean
}

// Define a common component so we can use hooks (`.map()` with `use` violates Rule of Hooks otherwise)
// DEV: We don't define this in components as it's unexpected those talk to server endpoints
const GlobalNotification = ({ notification }: GlobalNotificationTypes) => {
  const queryClient = useQueryClient()
  const accountId = notification.account_id
  const { mutate: deleteNotification } =
    useDeleteGlobalNotification(queryClient)
  const onDismiss = () => deleteNotification(notification.id)
  const accountUrl = buildAccountAbsoluteUrl(accountId)

  // GlobalNotifications don't use cycle info yet, so not resolving it

  if (notification.key === "added_to_account") {
    return (
      <Notification variant="success" onDismiss={onDismiss}>
        You’ve been given access to a new account:{" "}
        <Link to={accountUrl} className="link">
          {notification.account_name}
        </Link>
      </Notification>
    )
  }
}

const DashboardLayout = ({
  showGlobalNotifications = true,
  children,
  subNavigation,
  isLoading = [],
  isAccountsPage,
}: DashboardLayoutTypes) => {
  const queryClient = useQueryClient()
  const {
    data: globalNotifications = [],
    isLoading: globalNotificationsIsLoading,
  } = useGlobalNotifications<NotificationType[], Error>(queryClient, {
    enabled: showGlobalNotifications,
  })

  const dataLoaded = !!globalNotifications

  let notificationEls: ReactNode[] = []
  if (dataLoaded && globalNotifications) {
    notificationEls = globalNotifications.map(
      (notification: NotificationType) => (
        <GlobalNotification key={notification.id} notification={notification} />
      )
    )
  }

  // Return our layout
  return (
    <MainLayout
      // DEV: `.concat` works with both strings and arrays -- always a flattened array
      contentBg="grey"
      //   `['a'].concat('b') equals ['a'].concat(['b'])
      isLoading={[globalNotificationsIsLoading].concat(isLoading)}
    >
      {dataLoaded && (
        <>
          {subNavigation}
          {/* DEV: Match `container` used for children content below */}
          {notificationEls.length ? (
            <div className="container">
              <div className="inner-container mx-auto mt-8 mb-8 space-y-6">
                {notificationEls}
              </div>
            </div>
          ) : null}
          {/* DEV: Use `container` to set upper bound from container-perspective */}
          <div
            className={`container ${
              isAccountsPage ? "container max-w-1120" : "max-w-6xl"
            }`}
          >
            {/* DEV: Use `inner-container` inside `container` for secondary maximum (since both use `max-width`) */}
            {/* Pad to bottom so we're not super short */}
            <div
              className={cx(
                "inner-container mx-auto pb-6 md:pb-12",
                !notificationEls.length && "pt-6 md:pt-12"
              )}
            >
              {children}
            </div>
          </div>
        </>
      )}
    </MainLayout>
  )
}

export default DashboardLayout
