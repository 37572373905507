import React, { CSSProperties, useEffect } from "react"
import { Link, NavLink } from "react-router-dom"
import { Disclosure, DisclosureStateReturn } from "reakit/Disclosure"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/pro-duotone-svg-icons"

import NCXLogo from "../images/NCX---Logo---Full---White.png"

interface LinkTypes {
  href: string
  exact: boolean
  title: string
}

interface SlideInNavTypes {
  disclosure: DisclosureStateReturn
  links: LinkTypes[]
  onClick: () => void
}

export const SlideInNav = ({ disclosure, links, onClick }: SlideInNavTypes) => {
  useEffect(() => {
    if (!disclosure.visible) return

    // prevent page scroll when navigation is open
    document.body.classList.add("overflow-y-hidden")

    return () => {
      document.body.classList.remove("overflow-y-hidden")
    }
  }, [disclosure.visible])

  return (
    <>
      <button
        role="button"
        onClick={disclosure.hide}
        className={cx("slide-in-nav-overlay", {
          "slide-in-nav-overlay-visible": disclosure.visible,
        })}
      />

      <div className="slide-in-nav-content">
        <div className="relative h-6 w-full">
          <div className="absolute -right-0.5 top-0 z-overlay">
            <Disclosure
              {...disclosure}
              className="text-white hover:bg-brand-dark rounded h-6 w-6"
              aria-label="Close menu"
            >
              <i
                aria-hidden="true"
                className="flex items-center justify-center h-6 w-6"
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  style={
                    {
                      "--fa-primary-opacity": 0,
                      "--fa-secondary-opacity": 1,
                    } as CSSProperties
                  }
                />
              </i>
            </Disclosure>
          </div>
        </div>

        <div className="flex flex-col items-center justify-between w-full grow">
          <nav className="w-full">
            {links.map((item) => (
              <NavLink
                to={item.href}
                className={({ isActive }) => {
                  return cx(
                    "block p-3 rounded-md  text-lg font-bold leading-[140%] tracking-0.36 link--underline-only",
                    isActive ? "text-moss" : "text-white"
                  )
                }}
                onClick={() => {
                  onClick()
                  disclosure.hide()
                }}
                key={item.title}
              >
                {item.title}
              </NavLink>
            ))}
          </nav>

          <div className="flex items-center justify-center">
            <Link
              aria-label="Go to dashboard"
              to="/"
              className="p-2"
              onClick={disclosure.hide}
            >
              <img alt="NCX Logo" className="h-11" src={NCXLogo} />
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
