import * as yup from "yup"
import DOMPurify from "dompurify"
import { Form, Formik, FormikProps, FormikValues } from "formik"
import { useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"

import { ActionPermissionWrapper } from "./ActionPermissionWrapper"
import { Toast } from "./Toast"
import { SubmitButton } from "../components/SubmitButton"
import { WrappedInput } from "../components/Input"
import PreferredContactInput from "./PreferredContactInput"

import {
  phoneRegex,
  PREFERRED_CONTACT_METHOD,
  PHONE_NUMBER,
} from "../shared/constants"
import { genericErrMsg } from "../api/auth"
import {
  useUpdateProfile,
  useCreateAsanaTask,
  useAccountUrlPrefix,
} from "../hooks"
import { AccountRoleType } from "@/types/account"
import { AccountTypes } from "@/types/account"
import { AsanaTaskCreationParams } from "@/types/asana"
import { Profile } from "../types"

const QUESTION = "question"

interface FormValuesTypes {
  preferred_contact_method: string[]
  phone_number: string | undefined
  question: string
}

interface FormComponentTypes {
  formikProps: FormikProps<FormValuesTypes>
  account: AccountTypes | undefined
  isSubmitting: boolean
}

interface AskAQuestionFormTypes {
  account: AccountTypes | undefined
  profile: Profile | undefined
}

const validationSchema = (isValidPhoneNumber: boolean) => {
  return yup.object().shape({
    [PREFERRED_CONTACT_METHOD]: yup
      .array()
      .min(1, "Please provide a preferred contact method")
      .of(yup.string().required())
      .required("Please provide a preferred contact method"),
    [PHONE_NUMBER]: yup
      .string()
      .when(PREFERRED_CONTACT_METHOD, (methodsArray, schema) => {
        // methodsArray is an array of 1 array strings
        const methods = [...methodsArray[0]]
        return !isValidPhoneNumber &&
          (methods.includes("phone") || methods.includes("text"))
          ? yup
              .string()
              .required("Please enter a phone number.")
              .matches(phoneRegex, "Please provide a valid phone number")
          : schema
      }),
    [QUESTION]: yup
      .string()
      .min(1, "Please provide a question")
      .max(100, "Question must be less than 100 characters")
      .required("Please provide a question"),
  })
}

const FormComponent = ({
  formikProps,
  account,
  isSubmitting,
}: FormComponentTypes) => {
  const showPhoneField =
    formikProps.values.preferred_contact_method.includes("phone") ||
    formikProps.values.preferred_contact_method.includes("text")

  const charsRemaining = 100 - formikProps.values.question.length
  return (
    <Form className="inline">
      <div>
        <WrappedInput
          labelClass="label font-bold mb-2 leading-18px"
          aria-label="Question"
          label={`Your question (${charsRemaining} characters remaining)`}
          placeholder="Ask a question"
          type="text"
          name="question"
          className="border-charcoal-50 mb-4"
          maxLength={100}
        />
      </div>

      <div>
        <PreferredContactInput />
      </div>

      {showPhoneField ? (
        <div>
          <WrappedInput
            labelClass="label font-bold mb-2 mt-2 leading-18px"
            aria-label="Phone number"
            label="Phone number"
            placeholder="Phone number"
            type="text"
            name="phone_number"
          />
        </div>
      ) : null}

      <div className="space-y-p my-7">
        <p className="text-base text-charcoal-500 font-bold">
          Not sure where to start? Here are some questions we've received from
          landowners like you.
        </p>
        <p className="text-leaf">
          Is carbon or timber a better deal on my land?
        </p>
        <p className="text-leaf">
          Which program payments can I stack to earn more passive income from my
          woods?
        </p>
        <p className="text-leaf">
          What is the best program combo for a recreational property?
        </p>
      </div>

      <ActionPermissionWrapper
        accountRole={account?.role as AccountRoleType}
        action="editAccount"
      >
        <SubmitButton
          isSubmitting={isSubmitting}
          className="btn2 btn2-primary font-semibold ask-a-question-submit"
        >
          Submit
        </SubmitButton>
      </ActionPermissionWrapper>
    </Form>
  )
}

const AskAQuestionForm = ({ account, profile }: AskAQuestionFormTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { mutateAsync: updateProfile, status: updateProfileStatus } =
    useUpdateProfile(queryClient)
  const { mutateAsync: createAsanaTask, status: createAsanaTaskStatus } =
    useCreateAsanaTask(queryClient, {})

  const isSubmitting =
    updateProfileStatus === "pending" || createAsanaTaskStatus === "pending"

  const handleFormSubmit = async (values: FormikValues) => {
    const newContactValues = {
      preferred_contact_method: values.preferred_contact_method,
      ...(values.preferred_contact_method.includes("phone") ||
      values.preferred_contact_method.includes("text")
        ? { phone_number: values.phone_number }
        : {}),
    }

    const question = DOMPurify.sanitize(values.question as string)

    await updateProfile(newContactValues, {
      onSuccess: () => {
        const handleCreateAsanaTask = async () => {
          const env =
            import.meta.env.VITE_APP_SENTRY_ENV || import.meta.env.MODE
          // PROD ? "Ask a question pipeline"; else: "Ask a question"
          const project =
            env === "production" ? "1208353947761341" : "1208254240698105"
          const taskName = `${env !== "production" ? "TEST: " : ""} ${question}`
          const contactMethods =
            newContactValues?.preferred_contact_method.join(", ") || ""
          const description =
            `<body><b>Question</b>: ${taskName}` +
            `\n<b>isMember</b>: ${account?.is_member}` +
            `\n<b>First Name</b>: ${profile?.first_name}` +
            `\n<b>Last Name</b>: ${profile?.last_name}` +
            `\n<a href='https://bark-api.ncx.com/admin/ncapx_platform/profile/${profile?.id}'>Profile</a>` +
            `\n<a href='https://bark-api.ncx.com/admin/ncapx_platform/account/${account?.id}'>Account</a>` +
            `\n<b>Preferred Contact method(s)</b>: ${contactMethods}` +
            `\n${
              newContactValues?.phone_number || profile?.phone_number
                ? `<b>Phone Number</b>: ${
                    newContactValues?.phone_number || profile?.phone_number
                  }`
                : ""
            }` +
            `\n${
              profile?.email ? `<b>Email</b>: ${profile?.email}` : ""
            }</body>`

          const taskParams = {
            taskName,
            projects: [project],
            description,
            useHtmlDescription: true,
          }

          await createAsanaTask(taskParams as AsanaTaskCreationParams, {
            onSuccess: () => {
              Toast.success("Question submitted successfully!")
              navigate(`${accountUrlPrefix}/`)
            },
            onError: () => {
              Toast.error(genericErrMsg)
              navigate(`${accountUrlPrefix}/`)
            },
          })
        }
        handleCreateAsanaTask()
      },
      onError: () => {
        Toast.error(genericErrMsg)
        navigate(`${accountUrlPrefix}/`)
      },
    })
  }
  const isValidPhoneNumber = phoneRegex.test(profile?.phone_number || "")
  return (
    <Formik<FormValuesTypes>
      initialValues={{
        [PREFERRED_CONTACT_METHOD]: profile?.preferred_contact_method || [],
        [PHONE_NUMBER]: profile?.phone_number || "",
        [QUESTION]: "",
      }}
      validationSchema={() => validationSchema(isValidPhoneNumber)}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={handleFormSubmit}
    >
      {(formikProps) => (
        <FormComponent
          formikProps={formikProps}
          account={account}
          isSubmitting={isSubmitting}
        />
      )}
    </Formik>
  )
}

export default AskAQuestionForm
