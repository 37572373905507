import { Link, useLocation } from "react-router-dom"
import { useMenuState, Menu, MenuItem, MenuButton } from "reakit/Menu"
import { useQueryClient } from "@tanstack/react-query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import { useIsMultiAccount, useProfile } from "../hooks"
import { Profile } from "../types"

interface PlatformUserMenuTypes {
  isEmailVerified: boolean
  partnersNav: boolean
  isMember: boolean | undefined
}

export const PlatformUserMenu = ({
  isEmailVerified,
  partnersNav,
  isMember = true,
}: PlatformUserMenuTypes) => {
  const queryClient = useQueryClient()

  const menu = useMenuState()

  const isMultiAccount = useIsMultiAccount()

  const { data: profile } = useProfile<Profile, Error>(queryClient)

  let fullName = `${profile?.first_name || ""} ${
    profile?.last_name || ""
  }`.trim()
  let abbreviatedName = `${profile?.first_name || ""} ${
    profile?.last_name ? profile.last_name.charAt(0) + "." : ""
  }`.trim()
  fullName = fullName || profile?.email || ""
  abbreviatedName = abbreviatedName || profile?.email || ""

  const location = useLocation()
  const isOnboarding = location.pathname.includes("/onboarding")
  const isEmailVerifiedLO = isEmailVerified && partnersNav === false

  return (
    <>
      <MenuButton
        // {/* DEV: `overflow-hidden` is required for inner `truncate` to function */}
        className="relative media overflow-hidden text-white text-base px-2 py-4 ml-1 md:ml-3"
        data-test="menu-button"
        {...menu}
      >
        <span
          className={cx(
            "media-body truncate sm:hidden text-white",
            isMember || partnersNav ? "text-white" : "text-west-side"
          )}
        >
          {abbreviatedName}
        </span>

        <span
          className={cx(
            "hidden media-body truncate sm:block font-semibold",
            isMember || partnersNav ? "text-white" : "text-west-side"
          )}
        >
          {fullName}
        </span>

        <i
          className={cx(
            "media-figure ml-2",
            isMember || partnersNav ? "text-white" : "text-west-side"
          )}
        >
          <FontAwesomeIcon icon={faChevronDown} size="xs" />
        </i>

        {!isMember && !partnersNav ? (
          <span className="absolute left-0 right-5 bottom-2 sm:bottom-0.5 text-center text-sm text-west-side whitespace-nowrap">
            (non-member)
          </span>
        ) : null}
      </MenuButton>

      <Menu
        className="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5 min-w-32 z-overlay"
        {...menu}
        aria-label="User Action Menu"
      >
        {isEmailVerifiedLO && !isOnboarding ? (
          <MenuItem
            className="block px-4 py-2 text-sm text-gray-700 focus:ring-0 focus:underline link--underline-only"
            {...menu}
            as={Link}
            to="/settings"
            onClick={menu.hide}
          >
            {/* DEV: Both are personal settings but as a single-account profile,
                everything looks like "Account Settings" so this is consistent with user expectations */}
            {isMultiAccount ? "Personal Settings" : "Account Settings"}
          </MenuItem>
        ) : null}

        <MenuItem
          className="block px-4 py-2 text-sm text-gray-700 focus:ring-0 focus:underline link--underline-only"
          {...menu}
          as={Link}
          to="/logout"
          data-test="logout"
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}
