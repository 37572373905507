import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"

import MainLayout from "./_layouts/Main"
import BecomeAMemberWidget from "../components/BecomeAMemberWidget"
import { useAccount, useAccountId } from "../hooks"
import { Video } from "../components/Video"
import SIDEBAR_IMG from "../images/paywall.jpg"

const BecomeAMember = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const { scrollToTop } = location.state || {}

  const { data: account, isLoading: accountIsLoading } = useAccount(
    queryClient,
    accountId
  )

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [scrollToTop])

  if (account?.is_member) {
    navigate("/", { replace: true })
  }

  return (
    <MainLayout isLoading={[accountIsLoading]} contentBg="white">
      <div className="container px-4 pt-[92px] pb-[132px]">
        <h1 className="text-4xl md:text-[50px] text-charcoal-500 text-center font-medium leading-[124%] md:leading-[110%] tracking-0.4 md:tracking-[1px]">
          This feature is only available to NCX Members
        </h1>

        <BecomeAMemberWidget widgetType="page" dashboardLink />

        <div className="w-full max-w-[886px] mx-auto mt-9">
          <div className="md:flex">
            <div className="grow pr-6">
              <Video
                name="NCX Membership Preview"
                className="pb-8"
                src="https://9337776.fs1.hubspotusercontent-na1.net/hubfs/9337776/Demo%20Videos/NCX%20Membership%20Preview_Sept2024_final.mp4"
                thumbnailSrc="https://9337776.fs1.hubspotusercontent-na1.net/hubfs/9337776/Demo%20Videos/NCX%20Membership%20Preview_Sept%202024_thumbnail.png"
              />
              <h3 className="text-2xl text-autumn font-semibold leading-[124%] tracking-0.48">
                Why pay for NCX Membership?
              </h3>

              <p className="text-xl	text-charcoal-500 font-semibold leading-[140%] tracking-0.4 mt-3">
                You're in the right place for expert guidance about the natural
                capital on your land.
              </p>

              <p className="text-lg text-charcoal-500 leading-[140%] tracking-0.36 mt-7">
                <strong>
                  Honest and unbiased advice that puts your interests first.
                </strong>{" "}
                When you pay for a service, YOU are the customer. Landowners are
                #1 at NCX. Unlike other free services, NCX avoids conflicts of
                interest by not taking commissions or lead fees from project
                developers, timber buyers, or realtors. We understand that your
                land is a precious asset and you don't want to mess it up for
                future generations. As an NCX Member, you can be confident that
                YOU are our top priority and your interests come first.
              </p>

              <p className="text-lg text-charcoal-500 leading-[140%] tracking-0.36 mt-4">
                <strong>A team of experts working for you.</strong> Natural
                capital markets for carbon, timber, water, and wildlife are
                constantly evolving. It's a full-time job keeping up with all
                the changes. We know because... it's our full time job! NCX's
                team of foresters, computer engineers, and landowner support
                staff are constantly scanning public and private programs to
                make sure that you don't miss out on any opportunities.
              </p>

              <p className="text-lg text-charcoal-500 leading-[140%] tracking-0.36 mt-4">
                <strong>You're in it for the long haul. So are we.</strong> Land
                ownership is a long-term commitment and NCX is committed to your
                long-term success. By charging landowners like you a monthly
                membership fee, we can ensure NCX stays around for the long term
                without worrying about the constant ups and downs of carbon,
                timber, and other natural capital markets. Your membership
                allows us to constantly reinvest in new data, features, and
                analysis, making NCX the #1 place landowners go when they want
                to make informed and confident decisions about their land.
              </p>
            </div>

            <div className="w-full md:max-w-[300px] shrink-0 px-8 border-l border-autumn self-start mt-10 md:mt-[30px]">
              <img
                src={SIDEBAR_IMG}
                className="w-full h-auto block"
                alt="landowners"
              />

              <p className="text-base text-dusk leading-[140%] tracking-0.32 mt-4">
                <q>
                  One of the things that has kept me from committing to these
                  things in the past is the worry that I’m missing something
                  else that might be going on. But with this, I don’t have to
                  worry about that anymore.
                </q>{" "}
                <strong>- James</strong>
              </p>

              <p className="text-base text-dusk leading-[140%] tracking-0.32 mt-3">
                <q>
                  I feel like I have somebody who is looking after my interests
                  so that I don’t do something that costs me down the road.
                </q>{" "}
                <strong>- Paul</strong>
              </p>

              <p className="text-base text-dusk leading-[140%] tracking-0.32 mt-3">
                <q>
                  The NCX platform is easy to use and navigate and allowed me to
                  compare the costs and benefits of various programs in plain,
                  easy-to-understand language that incorporated the unique
                  attributes of my own property.
                </q>{" "}
                <strong>- Will</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default BecomeAMember
