import { useState, useEffect } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useLocation } from "react-router-dom"

import MainLayout from "../_layouts/Main"
import {
  useAccountId,
  useAccountProperty,
  useProfile,
  useGetAttestationQuizes,
  useAccountInterest,
} from "../../hooks"
import OnboardingSteps from "../../sections/Onboarding/OnboardingSteps"
import OnboardingReport from "../../sections/Onboarding/OnboardingReport"
import { Profile } from "../../types"
import { AccountProperty } from "../../types/property"
import { EligibilityQuizzes } from "@/types/eligibility"
import { AccountInterestTypes } from "@/types/accountInterest"

const OnboardingSurvey = () => {
  const accountId = useAccountId()
  const [onboardingStep, setOnboardingStep] = useState<number>(0)
  const [surveyCompleted, setSurveyCompleted] = useState<boolean>(false)
  const [showReport, setShowReport] = useState<boolean>(false)

  const location = useLocation()
  const queryClient = useQueryClient()
  const { data: profile, isLoading: profileIsLoading } = useProfile<
    Profile,
    Error
  >(queryClient)
  const { data: property, isLoading: propertyIsLoading } = useAccountProperty<
    AccountProperty,
    Error
  >(queryClient, accountId, {
    refetchInterval: (query) => {
      if (query.state?.data?.landcover_data || showReport) {
        return false
      }
      return 2000
    },
  })

  // this data connects project types to quiz categories for the report page
  const { data: quizzesData, isLoading: quizDataIsLoading } =
    useGetAttestationQuizes<EligibilityQuizzes, Error>(accountId)

  const { data: accountInterest, isLoading: accountInterestIsLoading } =
    useAccountInterest<AccountInterestTypes, Error>(queryClient, accountId)

  const fromDashboard = location?.state?.fromDashboard

  useEffect(() => {
    setOnboardingStep(0)
  }, [accountId])

  useEffect(() => {
    // send user to loading screen if they added parcels after survey
    if (location?.state?.refreshPropertyBoundaries) {
      setSurveyCompleted(true)
    }
    // otherwise, we we are coming from the loading screen so show report
    if (
      location?.pathname.includes("/onboarding/report") &&
      !location?.state?.refreshPropertyBoundaries
    ) {
      setShowReport(true)
    }
  }, [location?.pathname, location?.state?.refreshPropertyBoundaries])

  const progressStep = showReport
    ? 10
    : surveyCompleted
      ? 9
      : onboardingStep + 1

  const maxSteps = fromDashboard ? 7 : 10

  return (
    <MainLayout
      isLoading={[
        quizDataIsLoading,
        profileIsLoading,
        propertyIsLoading,
        accountInterestIsLoading,
      ]}
      contentBg="white"
      className="onboarding"
    >
      {showReport ? (
        <OnboardingReport
          accountId={accountId}
          profile={profile as Profile}
          property={property as AccountProperty}
          quizzesData={quizzesData as EligibilityQuizzes}
          progressStep={progressStep}
          maxSteps={maxSteps}
        />
      ) : (
        <>
          <OnboardingSteps
            onboardingStep={onboardingStep}
            setOnboardingStep={setOnboardingStep}
            surveyCompleted={surveyCompleted}
            setSurveyCompleted={setSurveyCompleted}
            progressStep={progressStep}
            maxSteps={maxSteps}
            accountInterest={accountInterest}
            fromDashboard={fromDashboard}
          />
        </>
      )}
    </MainLayout>
  )
}

export default OnboardingSurvey
