import React, { FC } from "react"
import { Link } from "react-router-dom"
import ForestIllustration from "../../src/images/forest-at-night.svg"

export const NoConnectedAccountsCard: FC = () => (
  <div className="relative flex bg-white px-12 py-10 overflow-hidden shadow-sm rounded mb-10  lg:px-20 lg:py-14">
    <img
      alt="Forest illustration"
      className="z-base+1 hidden  sm:inline-block"
      src={ForestIllustration}
    />

    <div className="text-carbon  sm:ml-14">
      <h3 className="text-xl">
        You're on your way to the nature-positive economy
      </h3>
      <p className="mt-1">
        To introduce a client, entity, or landowner that you manage to the NCX
        marketplace, get started by adding their property details.
      </p>
      <Link className="btn2 btn2-primary font-semibold mt-5" to="/accounts/new">
        Add Your First Account
      </Link>
    </div>
  </div>
)
