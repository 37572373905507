import assert from "assert"
import React from "react"
import { Link } from "react-router-dom"

interface SubNavigationTypes {
  title: string | null | undefined
  backUrl: string
  isAccountsPage?: boolean
  "aria-label": string
}

export const SubNavigation = ({
  title,
  backUrl,
  isAccountsPage,
  "aria-label": ariaLabel,
}: SubNavigationTypes) => {
  assert(ariaLabel, "aria-label required by SubNavigation for back arrow a11y")

  return (
    <div className="bg-white border-b border-cloud py-5 md:py-6">
      <div
        className={`container ${
          !isAccountsPage ? "max-w-1120 px-5" : "max-w-6xl px-14"
        }`}
      >
        <div className="inner-container mx-auto flex items-center">
          <Link
            aria-label={ariaLabel}
            className="flex justify-center items-center w-9 h-9 mr-4 md:w-10 md:h-10 border shadow rounded-full focus:outline-none focus:ring focus:ring-blue-400 focus:ring-opacity-45"
            to={backUrl}
          >
            <i className="fas fa-arrow-left text-moss" />
          </Link>

          {title && (
            <div className="text-lg text-carbon font-semibold">{title}</div>
          )}
        </div>
      </div>
    </div>
  )
}
